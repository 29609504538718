<!--
 * @Author: your name
 * @Date: 2022-04-01 14:45:38
 * @LastEditTime: 2022-04-14 11:07:49
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\views\management\equipment_list.vue
-->
<template>
  <div id="body">
    <div class="fatherBox">

      <div class="query">
        <div class="queryLeft">

          <div style="width: 300px; float: left">
            设备类型：
            <el-select v-model="select_value" class="select" clearable>
              <el-option v-for="item in equipment_type" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>

        </div>
        <div class="queryRight">
          <el-button type="primary" @click="searchStu(1)">查询</el-button>
        </div>

      </div>
      <!-- 音视频 -->
      <div v-if="equipment_value == 6">
        <el-table :data="video_list" border style="width: 100%">
          <el-table-column  label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="ptel_num" label="普通电话次数"></el-table-column>
          <el-table-column prop="ptel_time" label="普通电话时长"></el-table-column>
          <el-table-column prop="ytel_num" label="语音通话次数"></el-table-column>
          <el-table-column prop="ytel_time" label="语音电话时长"></el-table-column>
          <el-table-column prop="stel_num" label="视频通话次数"></el-table-column>
          <el-table-column prop="stel_time" label="视频通话时长"></el-table-column>

          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">

              <el-button size="mini" type="primary" plain @click="detail(equipment_value, scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>
      <!-- 视力测试 -->
      <div v-else-if="equipment_value == 1">
        <el-table :data="eye_list" border style="width: 100%">
          <el-table-column  label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="jsum" label="近视数"></el-table-column>
          <el-table-column prop="jsl" label="近视率"></el-table-column>
          <el-table-column prop="ssum" label="测试总数"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">

              <el-button size="mini" type="primary" plain @click="detail(equipment_value, scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="total,prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>

      </div>

      <!-- 智能电脑验光仪 -->
      <div v-else-if="equipment_value== 5">
        <el-table :data="ygy_list" border style="width: 100%">
          <el-table-column  label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="ssum" label="检测人数"></el-table-column>
          <!-- <el-table-column prop="ssum" label="检测近视率"></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">
              <el-button size="mini" type="primary" plain @click="detail(equipment_value, scope.row)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>
      </div>
      <!-- 校园卫士 -->
      <div v-else-if="equipment_value == 4">
        <el-table :data="camera_list" border style="width: 100%">
          <el-table-column  label="序号" width="200" align="center">
            <template slot-scope="scope">
              {{ (curr_page - 1) * page_size + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="jnum" label="进校人数"></el-table-column>
          <el-table-column prop="cnum" label="出校人数"></el-table-column>
          <el-table-column prop="ynum" label="异常人数"></el-table-column>
          <el-table-column prop="topennum" label="推送打开率"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100" key="slot">
            <template #default="scope">
              <el-button size="mini" type="primary" plain @click="detail(equipment_value, scope.row)">详情
              </el-button>
            </template>
          </el-table-column>

        </el-table>

        <div class="block">
          <el-pagination @current-change="handleVideoChange" :current-page.sync="curr_page" :page-size="page_size"
            background layout="prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
          </el-pagination>
        </div>
      </div>
      <!-- 线图 -->
      <div class="fatherBox" style="margin-top: 10px;">
        <div class="ClassInfo_box">
          <div class="chartTitle">

            <ul>
              <li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index,item)"
                :class="currentIndex == index ? 'active' : ''" style="margin-top: 16px;">
                近{{ item }}天
              </li>
              <li>
                <el-date-picker v-model="searchData" type="daterange" size="small" :picker-options="pickerOptions"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                  value-format="timestamp">
                </el-date-picker>

                <el-button type="primary" style="padding: 9px 20px; margin-left: 10px;" @click="search">查询
                </el-button>

              </li>
            </ul>

          </div>
          <div class="chartTitle" style="margin-top:30px" v-if="equipment_value == 6">
            <el-radio-group v-model="video_num" style="margin-left:30px;margin-bottom: 30px;float: left;"
              @change="authenticationClick">
              <el-radio-button label="1">音视频使用次数数据</el-radio-button>
              <el-radio-button label="2">音视频使用时长数据</el-radio-button>
            </el-radio-group>
          </div>
          <div id="chart" style="width: 1000px;height:500px; background: white"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    machine_details_video_day,
    machine_details_eye_day,
    machine_details_ygy_day,
    machine_details_camera_day,
    machine_details_video_day_pic,
    machine_details_eye_day_pic,
    machine_details_camera_day_pic,
    machine_details_ygy_day_pic
  } from '@/api/agent_newApi.js'

  export default {
    data() {
      return {
        curr_page: 1, // 初始页
        page_size: 10, // 每页显示条目个数
        count_num: 0, // 总条数
        ygy_list: [], //验光仪
        video_list: [], //音视频列表
        eye_list: [], //视力列表
        camera_list: [], //校园卫士
        equipment_type: [{
            id: 1,
            title: '视力检测设备'
          },
          {
            id: 4,
            title: '校园卫士'
          }, {
            id: 5,
            title: '智能电脑验光仪'
          }, {
            id: 6,
            title: '音视频设备'
          }
        ],
        select_value: 1,
        equipment_value: 1,
        dateList: ['3', '7', '30'], // 选项
        currentIndex: 0,
        dataType: '', //搜索类型
        searchData: [], //搜索时间
        Series: [], // 折线图Y轴数据
        Dates: [], // 折线图X轴数据
        //时间选手
        video_num: '1', //音视频折线
        pickerOptions: {
          // 设置不能选择的日期
          onPick: ({
            maxDate,
            minDate
          }) => {
            this.choiceDate0 = minDate.getTime();
            if (maxDate) {
              this.choiceDate0 = '';
            }
          },
          disabledDate: (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            if (this.choiceDate0) {
              return time.getTime() < min || time.getTime() > max;
            }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
        },
      }
    },
    created() {

      if (localStorage.getItem('curr_page') != null) {

        this.curr_page = parseInt(localStorage.getItem('curr_page'));
        localStorage.setItem('curr_page', null)
      }

      if (localStorage.getItem('equipment_value') != null && !isNaN(localStorage.getItem('equipment_value'))) {

        this.equipment_value = parseInt(localStorage.getItem('equipment_value'));
        this.select_value = parseInt(localStorage.getItem('equipment_value'));
        localStorage.setItem('equipment_value', null)
      }

      if (localStorage.getItem('currentIndex') != null && !isNaN(localStorage.getItem('currentIndex'))) {

        this.currentIndex = parseInt(localStorage.getItem('currentIndex'));
        localStorage.setItem('currentIndex', null)
      }

      if (localStorage.getItem('video_num') != null && !isNaN(localStorage.getItem('currentIndex'))) {
        this.video_num = parseInt(localStorage.getItem('video_num'));
        localStorage.setItem('video_num', null)
      }

    },
    mounted() {

      this.get_machine_list(this.curr_page, this.currentIndex)
      //折线图
      this.eyePic(1);
    },
    methods: {
      select_type(val) {
        this.select_value = val;
      },
      searchStu() {
        this.equipment_value = this.select_value;
        if(this.equipment_value == 1){
          this.eyePic(1);
        }else if(this.equipment_value == 4){
          this.cameraPic(1);
        }else if(this.equipment_value == 5){
          this.ygyPic(1);
        }else if(this.equipment_value == 6){
          this.videoPic(1,1);
        }else if(this.equipment_value == 7){
          //this.eyePic(1);
        }else if(this.equipment_value == 8){
          //this.eyePic(1);
        }

        this.curr_page = 1;
        this.equipment_list = [];
        this.get_machine_list(1, 0);
      },
      //音视频 切换
      authenticationClick: function (select) {
        this.video_num = select;
        this.videoPic(1, this.video_num);
        this.currentIndex = 0

      },

      //获取列表
      get_machine_list(curr_page, current_index) {

        this.loading = true

        const data = {
          limit: this.page_size,
          page: curr_page
        };

        if (this.equipment_value == 6) {
          //音视频列表

          machine_details_video_day(data).then(res => {
            if (res.status.code == 1) {

              this.video_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page

              // //折线图
              // this.videoPic(1, this.video_num);
              this.currentIndex = current_index
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })

        } else if (this.equipment_value == 5) {
          //验光仪
          machine_details_ygy_day(data).then(res => {

            if (res.status.code == 1) {
              this.ygy_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page
              // this.ygyPic(1);
              this.currentIndex = current_index
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        } else if (this.equipment_value == 1) {
          //视力检测
          machine_details_eye_day(data).then(res => {

            if (res.status.code == 1) {
              this.eye_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page

              // //折线图
              this.currentIndex = current_index
              // this.eyePic(1);

            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        } else if (this.equipment_value == 4) {
          //校园卫士
          machine_details_camera_day(data).then(res => {

            if (res.status.code == 1) {
              this.camera_list = res.data.List;
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page

              // //折线图数据
              this.currentIndex = current_index
              // this.cameraPic(1);
            } else {
              this.$message.error(res.status.msg)
            }

          }).catch(err => {
            console.log(err)
          })
        }

      },

      handleVideoChange(val) {
        this.curr_page = val
        this.get_machine_list(val, this.currentIndex);
      },
      Atpitch(index, item) { // 折线图时间选择索引
        this.currentIndex = index

        switch (index) {
          case 0:
            if (this.equipment_value == 6) {
              this.videoPic(1, this.video_num);
              this.doThis(3);
            } else if (this.equipment_value == 1) {
              this.eyePic(1);
              this.doThis(3);
            } else if (this.equipment_value == 4) {
              this.cameraPic(1);
              this.doThis(3);
            } else if (this.equipment_value == 4) {
              this.ygyPic(1);
              this.doThis(3);
            }

            break
          case 1:
            if (this.equipment_value == 6) {
              this.videoPic(2, this.video_num);
              this.doThis(7);
            } else if (this.equipment_value == 1) {
              this.eyePic(2);
              this.doThis(7);
            } else if (this.equipment_value == 4) {
              this.cameraPic(2);
              this.doThis(7);
            } else if (this.equipment_value == 5) {
              this.ygyPic(2);
              this.doThis(7);
            }
            break
          case 2:
            if (this.equipment_value == 6) {
              this.videoPic(3, this.video_num);
              this.doThis(30);
            } else if (this.equipment_value == 1) {
              this.eyePic(3);
              this.doThis(30);
            } else if (this.equipment_value == 4) {
              this.cameraPic(3);
              this.doThis(30);
            } else if (this.equipment_value == 5) {
              this.ygyPic(3);
              this.doThis(30);
            }
            break
          default:
            if (this.equipment_value == 6) {
              this.videoPic(1, this.video_num);
              this.doThis(3);
            } else if (this.equipment_value == 1) {
              this.eyePic(1);
              this.doThis(3);
            } else if (this.equipment_value == 4) {
              this.cameraPic(1);
              this.doThis(3);
            } else if (this.equipment_value == 5) {
              this.ygyPic(1);
              this.doThis(3);
            }
        }
      },
      //选择时间
      doThis(num) {
        let new_date = new Date(); //获取系统当前时间
        let start_date = (new_date.getTime()).toFixed(0);
        let end_date = (new Date(new_date.getTime() - num * 24 * 60 * 60 * 1000).getTime()).toFixed(0);
      },
      search() {
        if (this.searchData.length < 2) {
          this.$message.error('请填写搜索时间')
          return false;
        }
        if (this.equipment_value == 6) {
          this.videoPic(4, this.video_num);
          this.currentIndex = -1
        } else if (this.equipment_value == 1) {
          this.eyePic(4);
          this.currentIndex = -1
        } else if (this.equipment_value == 4) {
          this.cameraPic(4);
          this.currentIndex = -1
        } else if (this.equipment_value == 5) {
          this.ygyPic(4);
          this.currentIndex = -1
        }

      },

      // 音视频折线图日期数据
      videoPic(type, video_num) {

        const data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          startDate: Math.floor(this.searchData[0] / 1000), //开始时间 时间戳
          endDate: Math.floor(this.searchData[1] / 1000), //结束时间 时间戳
        }

        machine_details_video_day_pic(data).then(res => {
          this.loading = false
          this.Dates = res.data.date;
          if (video_num == 1) {
            this.Series = [{
                name: '普通电话次数',
                type: 'line',
                data: res.data.ptelnum
              },
              {
                name: '语音电话次数',
                type: 'line',
                data: res.data.ytelnum
              },
              {
                name: '视频电话次数',
                type: 'line',
                data: res.data.stelnum
              },
            ];

            this.video_chart() // 执行Echarts
          } else {
            this.Series = [{
                name: '普通电话时长',
                type: 'line',
                data: res.data.ptelhour
              },
              {
                name: '语音电话时长',
                type: 'line',
                data: res.data.ytelhour
              },
              {
                name: '视频电话时长',
                type: 'line',
                data: res.data.stelhour
              },
            ];

            this.video_num_chart() // 执行Echarts
          }


        }).catch(err => {
          console.log(err)
        })

      },
      //视力折线图
      eyePic(type) {

        const data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          startDate: Math.floor(this.searchData[0] / 1000), //开始时间 时间戳
          endDate: Math.floor(this.searchData[1] / 1000), //结束时间 时间戳
        }

        machine_details_eye_day_pic(data).then(res => {

          if (res.status.code == 1) {
            this.Dates = res.data.date;
            this.Series = [{
                name: '检测数',
                type: 'line',
                data: res.data.num
              },
              {
                name: '近视率',
                type: 'line',
                data: res.data.numjin
              },

            ];
            this.eye_chart() // 执行Echarts
          } else {
            this.$message.error(res.status.msg)
          }

        }).catch(err => {
          console.log(err)
        })

      },
      //验光仪折线图数据
      ygyPic(type) {

        const data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          startDate: Math.floor(this.searchData[0] / 1000), //开始时间 时间戳
          endDate: Math.floor(this.searchData[1] / 1000), //结束时间 时间戳
        }

        machine_details_ygy_day_pic(data).then(res => {
          console.log(res)
          if (res.status.code == 1) {
            this.Dates = res.data.date;
            this.Series = [{
                name: '检测人数',
                type: 'line',
                data: res.data.num
              },


            ];
            this.ygy_chart() // 执行Echarts
          } else {
            this.$message.error(res.status.msg)
          }

        }).catch(err => {
          console.log(err)
        })

      },
      //验光仪折线图 执行
      ygy_chart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {
          tooltip: {
            trigger: 'axis'
          },

          legend: {
            data: ['检测人数', '出校人数']
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },
      //校园卫士折线图数据
      cameraPic(type) {

        const data = {
          dataType: type, // 类型 ( 1,2,3,4 )
          startDate: Math.floor(this.searchData[0] / 1000), //开始时间 时间戳
          endDate: Math.floor(this.searchData[1] / 1000), //结束时间 时间戳
        }

        machine_details_camera_day_pic(data).then(res => {

          if (res.status.code == 1) {
            this.Dates = res.data.date;
            this.Series = [{
                name: '进校人数',
                type: 'line',
                data: res.data.jnum
              },
              {
                name: '出校人数',
                type: 'line',
                data: res.data.cnum
              },

            ];
            this.camera_chart() // 执行Echarts
          } else {
            this.$message.error(res.status.msg)
          }

        }).catch(err => {
          console.log(err)
        })

      },

      //校园卫士折线图 执行
      camera_chart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {
          tooltip: {
            trigger: 'axis'
          },

          legend: {
            data: ['进校人数', '出校人数']
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },

      //视力折线图 执行
      eye_chart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {

          tooltip: {
            trigger: 'axis'

          },

          legend: {
            data: ['检测数', '近视率', ]
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },
      //音视频折线图 执行
      video_chart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {
          tooltip: {
            trigger: 'axis'
          },

          legend: {
            data: ['普通电话次数', '语音电话次数', '视频电话次数']
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },
      //音视频折线图 执行
      video_num_chart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('chart'))
        const options = {
          tooltip: {
            trigger: 'axis',

          },

          legend: {
            data: ['普通电话时长', '语音电话时长', '视频电话时长']
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: that.Series
        }

        myChart.setOption(options, true)
      },

      detail(type, row) {
        localStorage.setItem('Route', 'equipment_management') // 存跳转标识
        localStorage.setItem('curr_page', this.curr_page) // 存跳转分页面
        localStorage.setItem('equipment_value', type) // 存类型
        localStorage.setItem('currentIndex', this.currentIndex) // currentIndex
        localStorage.setItem('video_num', this.video_num) // video_num

        this.$router.push({
          name: 'equipment_schools_detail',
          params: {
            date: row.date,
            type: type
          }
        })
      },

    }
  }

</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      // border-bottom:1px solid #cccccc ;
      .head_dates {
        display: inherit;
        margin-left: 102px;
        font-weight: bold;
      }

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 54px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }

</style>
